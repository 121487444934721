import React, { useState } from 'react';
import './Login.css';
import GoogleImg from '../assets/google.png';
import AppleImg from '../assets/apple.png';
import EmailImg from '../assets/email.png';
import PassImg from '../assets/password.png';
import SignUp from './SignUp';
import Forgot from './Forgot';
import Thanku from './Thanku';

const Login = ({ isOpen, onClose }) => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isForgotOpen, setIsForgotOpen] = useState(false);
  const [isThankuOpen, setIsThankuOpen] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpOpen(true);
    setIsForgotOpen(false);
  };

  const handleCloseSignUp = () => {
    setIsSignUpOpen(false);
    setIsForgotOpen(false);
    onClose();
  };

  const handleForgotClick = () => {
    setIsForgotOpen(true);
    setIsSignUpOpen(false);
  };

  const handleCloseForgot = () => {
    setIsForgotOpen(false);
    setIsSignUpOpen(false);
    onClose();
  };

  const handleThankuClick = () => {
    setIsThankuOpen(true);
    setIsSignUpOpen(false);
    setIsForgotOpen(false);
  };

  const handleCloseThanku = () => {
    setIsThankuOpen(false);
    setIsSignUpOpen(false);
    setIsForgotOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      {!isSignUpOpen && !isForgotOpen && !isThankuOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <form className="login-form">
              <button className="close-button" onClick={onClose}>&times;</button>
              <h2>Email</h2>
              <div className="input-container-new">
                <img src={EmailImg} className="input-icon" alt="Email Icon" />
                <input
                  className='login-input'
                  type="email"
                  placeholder="Enter your Email"
                  required
                />
              </div>
              <h2>Password</h2>
              <div className="input-container-new">
                <img src={PassImg} className="input-icon" alt="Password Icon" />
                <input
                  className='login-input'
                  type="password"
                  placeholder="Enter your Password"
                  required
                />
              </div>
              <div className="options">
                <label>
                  <input type="checkbox" /> Remember me
                </label>
                <p className="forgot-password" onClick={handleForgotClick}>Forgot password?</p>
              </div>
              <button type="submit" className="signin-btn" onClick={handleThankuClick}>Sign In</button>
              <div className="signup-container">
                <p>Don't have an account? <span className="signup-link" onClick={handleSignUpClick}>Sign Up</span></p>
                <br />
                <p>Or With</p>
                <div className="social-login">
                  <button className="social-btn google-btn">
                    <img src={GoogleImg} alt="Google" className="social-icon" />
                    Google
                  </button>
                  <button className="social-btn apple-btn">
                    <img src={AppleImg} alt="Apple" className="social-icon" />
                    Apple
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {isSignUpOpen && <SignUp isOpen={isSignUpOpen} onClose={handleCloseSignUp} />}
      {isForgotOpen && <Forgot isOpen={isForgotOpen} onClose={handleCloseForgot} />}
      {isThankuOpen && <Thanku isOpen={isThankuOpen} onClose={handleCloseThanku} />}
    </>
  );
};

export default Login;
