import React, { useState, useEffect } from 'react';
import './Subscribe.css';
import Thanku from './Thanku';
import EmailImg from '../assets/email.png';

const Subscribe = ({ isOpen, onClose, selectedOption }) => {
  const [isThankuOpen, setIsThankuOpen] = useState(false);
  const [selectedSubscribeOption, setSelectedSubscribeOption] = useState(''); // Track selected subscription option

  useEffect(() => {
    if (selectedOption) {
      setSelectedSubscribeOption(selectedOption);
    }
  }, [selectedOption]);

  const handleThankuClick = () => {
    setIsThankuOpen(true);
  };

  const handleCloseThanku = () => {
    setIsThankuOpen(false);
    onClose(); // Close the Subscribe modal when Thank You modal is closed
  };

  const handleSelectChange = (event) => {
    setSelectedSubscribeOption(event.target.value);
  };

  if (!isOpen) return null;

  return (
    <>
      {!isThankuOpen && (
        <div className="modal-overlay6">
          <div className="modal-content6">
            <button className="close-button" onClick={onClose}>&times;</button>
            <h2>Email</h2>
            <div className="input-container-new">
              <img src={EmailImg} className="input-icon" alt="Email Icon" />
              <input
                className='login-input'
                type="email"
                placeholder="Enter your Email"
                required
              />
            </div>
            <h2>Whatsapp No</h2>
            <div className="input-container-new">
              <span className="input-icon">+91</span>
              <input
                className='login-input'
                type="number"
                placeholder="Enter your phone number"
                required
              />
            </div>
            <label className='subscribeList-heading'>Select Updates Regarding
              <select className='subscribe-options' value={selectedSubscribeOption} onChange={handleSelectChange}>
                <option>Select</option>
                <option>Real Estate Investment</option>
                <option>Stock Market Investment</option>
                <option>Angel Funding Equity Investment</option>
                <option>Retirement Investment</option>
              </select>
            </label>
            <button type="submit" className="signin-btn" onClick={handleThankuClick}>Subscribe Us</button>
          </div>
        </div>
      )}
      {isThankuOpen && <Thanku isOpen={isThankuOpen} onClose={handleCloseThanku} />}
    </>
  );
};

export default Subscribe;
