import { useState } from 'react';
import './Home.css';
import Submit from '../../assets/send.png';
import backgroundImage1 from '../../assets/estate.jpeg';
import backgroundImage2 from '../../assets/stock.jpeg';
import backgroundImage3 from '../../assets/equity.jpeg';
import backgroundImage4 from '../../assets/retired.jpeg';
import Login from '../../authentication/Login';
import Subscribe from '../../authentication/Subscribe';

const Home = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null); // State to track selected subscription option

  const handleLoginClick = () => {
    setIsLoginOpen(true);
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleSubscribeClick = (subscription) => {
    setIsSubscribeOpen(true);
    setSelectedSubscription(subscription); // Set the selected subscription option
  };

  const handleCloseSubscribe = () => {
    setIsSubscribeOpen(false);
    setSelectedSubscription(null); // Clear selected subscription option when closing
  };

  const boxBackgrounds = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4
  ];

  return (
    <div className='home'>
      <div className="left-text">
        <p>INVEST369.AI</p>
        <button className='login-button' onClick={handleLoginClick}>LOGIN</button>
      </div>
      <div className="page-container">
        <div className="box-container">
          <div className="box" style={{ backgroundImage: `url(${boxBackgrounds[0]})` }} onClick={() => handleSubscribeClick('Real Estate Investment')}>Real Estate<br /> Investment</div>
          <div className="box" style={{ backgroundImage: `url(${boxBackgrounds[1]})` }} onClick={() => handleSubscribeClick('Stock Market Investment')}>Stock Market<br /> Investment</div>
          <div className="box" style={{ backgroundImage: `url(${boxBackgrounds[2]})` }} onClick={() => handleSubscribeClick('Angel Funding Equity Investment')}>Angel Funding<br /> Equity Investment</div>
          <div className="box" style={{ backgroundImage: `url(${boxBackgrounds[3]})` }} onClick={() => handleSubscribeClick('Retirement Investment')}>Just Retired?<br />Invest Here</div>
        </div>
        <div className="box-container">
          <div className="box new-box">What are the potential risks associated with investing in emerging markets?</div>
          <div className="box new-box">How does geopolitical risk influence global investment markets?</div>
          <div className="box new-box">How does diversification help in mitigating investment risk?</div>
          <div className="box new-box">What is the significance of risk tolerance in creating an investment strategy?</div>
        </div>
      </div>
      <div className="form-component">
        <div className="input-container">
          <input
            type="text"
            placeholder="Ask Anything...."
          />
          <div className='submit-button'>
            <button><img src={Submit} alt="Chat" className="submiticon" /></button>
          </div>
        </div>
      </div>
      <Login isOpen={isLoginOpen} onClose={handleCloseLogin} />
      <Subscribe isOpen={isSubscribeOpen} onClose={handleCloseSubscribe} selectedOption={selectedSubscription} />
    </div>
  );
};

export default Home;
