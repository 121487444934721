import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/Home/Home';
import Retirement from './components/RetirementCalci/Retirement';
import Login from './authentication/Login';
import SignUp from './authentication/SignUp';
import Forgot from './authentication/Forgot'
import MyPortfolio from './components/portfolio/MyPortfolio';
import Subscribe from './authentication/Subscribe';

function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/retirement" element={<Retirement />} />
          <Route path='/login' element={<Login />}/>
          <Route path='/signup' element={<SignUp />}/>
          <Route path='/forgot' element={<Forgot />}/>
          <Route path='/subscribe' element={<Subscribe/>}/>
          <Route path='/myportfolio' element={<MyPortfolio />}/> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
