import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from './invest369logo 2.png';
import ChatIcon from '../../assets/chat.png';
import SavedIcon from '../../assets/saved.png';
import SettingsIcon from '../../assets/setting.png';
import HelpIcon from '../../assets/help.png';
import UpgradeIcon from '../../assets/upgrade.png';
import NewChatIcon from '../../assets/newchat.png';
import Subscribe from '../../authentication/Subscribe';
import './Sidebar.css';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    setIsSubscribeOpen(true);
  };

  const handleCloseSubscribe = () => {
    setIsSubscribeOpen(false);
  };

  const handlePortfolioClick = () => {
    navigate('/myportfolio');
  };

  const handleChatClick = () => {
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <button className="toggle-sidebar" onClick={toggleSidebar}>
        {isSidebarOpen ? <FiX /> : <FiMenu />}
      </button>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="new-chat">
            <button onClick={handleChatClick}><img src={NewChatIcon} alt="New Chat" className="newchat" />New Chat</button>
            <button onClick={handlePortfolioClick} className='portfolio'>My Portfolio</button>
          </div>
        </div>
        <ul className="sidebar-menu">
          <li><button><img src={ChatIcon} alt="Chat" className="icon" />Chat</button></li>
          <li><button><img src={SavedIcon} alt="Saved" className="icon" />Saved</button></li>
          <li><button><img src={SettingsIcon} alt="Settings" className="icon" />Setting</button></li>
          <li><button><img src={HelpIcon} alt="Help" className="icon" />Help</button></li>
        </ul>
        <div className='sidebar-bottom'>
          <button className="upgrade" onClick={handleSubscribeClick}><img src={UpgradeIcon} alt="Upgrade" className="icon" />Subscribe</button>
          <br />
          <button className="upgrade"><img src={UpgradeIcon} alt="Upgrade" className="icon" />Upgrade to Premium</button>
        </div>
        <div className='sidebar-footer'>
          <h5>Connect with us</h5>
          <div className='social-icons'>
            <a href="https://wa.me/919799962369" target="_blank" rel="noopener noreferrer" className="social-icon">
              <IoLogoWhatsapp />
            </a>
            <a href="https://www.instagram.com/invest369.ai?igsh=dnZncHh2eHllbzQz" target="_blank" rel="noopener noreferrer" className="social-icon">
              <RiInstagramFill />
            </a>
            <a href="https://www.linkedin.com/company/invest-369-ai/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaLinkedin />
            </a>
            <a href="https://facebook.com/your-page" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaFacebook />
            </a>
            <a href="https://twitter.com/your-profile" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
      <Subscribe isOpen={isSubscribeOpen} onClose={handleCloseSubscribe} />
    </>
  );
};

export default Sidebar;
