import React from 'react'
import './Forgot.css'
import { useState } from 'react';
import SignUp from './SignUp';

const Forgot = ({ isOpen, onClose }) => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpOpen(true);
  };

  const handleCloseSignUp = () => {
    setIsSignUpOpen(false);
    onClose();
   
  };

  if (!isOpen) return null;
  return (
    <>
    {!isSignUpOpen &&  (
      <div className="modal-overlay2">
        <div className="modal-content2">
          <h2 className='signup-heading'>Forgot Password</h2>
          <button className="close-button"onClick={onClose}>&times;</button>
          <h2>Email</h2>
              <div className="input-container2">
                <input
                  className='signup-input'
                  type="email"
                  placeholder="Enter your Email"
                  required
                />
              </div>
              <button type="submit" className="signin-btn">Send Email</button>
              <div className="signup-container">
            <p>Don't have an account? <span className="signup-link"onClick={handleSignUpClick} >Sign Up </span>now</p>
          </div>
        </div>
      </div>
    )}
      {isSignUpOpen && <SignUp isOpen={isSignUpOpen} onClose={handleCloseSignUp} />}
    </>
  )
}

export default Forgot
