import React, { useState } from 'react';
import './MyPortfolio.css';

const MyPortfolio = () => {
    const [stocks, setStocks] = useState([
        { name: 'ITC', quantity: '50 Qty', price: '₹5,500', date: '21/10/21' },
        { name: 'TATA STEEL', quantity: '500 Qty', price: '₹85,500', date: '05/08/20' },
        { name: 'IRFC', quantity: '10 Qty', price: '₹12,000', date: '08/01/21' },
    ]);

    const handleInputChange = (index, field, value) => {
        const newStocks = [...stocks];
        newStocks[index][field] = value;
        setStocks(newStocks);
    };

    const handleAddStock = () => {
        setStocks([...stocks, { name: '', quantity: '', price: '', date: '' }]);
    };

    const handleRemoveStock = () => {
        if (stocks.length > 0) {
            const newStocks = stocks.slice(0, -1);
            setStocks(newStocks);
        }
    };

    return (
        <div className="my-portfolio-container">
            <div className="my-heading-container">
                <h1 className="my-portfolio-title">My Portfolio</h1>
                <h2 className="my-portfolio-subtitle">Add your owned stocks to your portfolio</h2>
            </div>
            <div className="my-stocks-container">
                {stocks.map((stock, index) => (
                    <div key={index} className="my-stock-section">
                        <h3 className="my-stock-title">STOCK {index + 1} :</h3>
                        <div className="my-stock-details">
                            <div className="my-stock-input">
                                <label>*Stock Name</label>
                                <input
                                    type="text"
                                    value={stock.name}
                                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                />
                            </div>
                            <div className="my-stock-input">
                                <label>*Quantity purchased</label>
                                <input
                                    type="text"
                                    value={stock.quantity}
                                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                />
                            </div>
                            <div className="my-stock-input">
                                <label>*Price purchased</label>
                                <input
                                    type="text"
                                    value={stock.price}
                                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                />
                            </div>
                            <div className="my-stock-input">
                                <label>*Date of purchase</label>
                                <input
                                    type="text"
                                    value={stock.date}
                                    onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="my-buttons-container">
                <button className="my-action-button" onClick={handleAddStock}>➕ Add more stocks</button>
                <button className="my-action-button" onClick={handleRemoveStock}>➖ Remove stock</button>
                <button className="my-action-button">➕ Add Real Estate</button>
            </div>
        </div>
    );
};

export default MyPortfolio;
