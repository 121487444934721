import React, { useState } from 'react';
import './Retirement.css'; 

const Retirement = () => {
  const [currentAge, setCurrentAge] = useState(18);
  const [retirementAge, setRetirementAge] = useState(30);

  return (
    <div className="container">
      <div className="steps">
        <div className="step active">01</div>
        <div className="line"></div>
        <div className="step">02</div>
        <div className="line"></div>
        <div className="step">03</div>
        <div className="line"></div>
        <div className="step">04</div>
        <div className="line"></div>
        <div className="step">05</div>
      </div>
      <div className="step-labels">
        <div className="label">Personal Information</div>
        <div className="label">Income Details</div>
        <div className="label">Saving Investment</div>
        <div className="label">Expenses</div>
        <div className="label">Summary</div>
      </div>
      <div className="content">
        <h2>01 PERSONAL INFORMATION</h2>
        <div className="input-group">
          <label>*Current Age</label>
          <input
            type="number"
            value={currentAge}
            onChange={(e) => setCurrentAge(e.target.value)}
          />
          <div className="slider">
            <input
              type="range"
              min="18"
              max="100"
              value={currentAge}
              onChange={(e) => setCurrentAge(e.target.value)}
            />
            <div className="slider-labels">
              <span>18</span>
              <span>100</span>
            </div>
          </div>
        </div>
        <div className="input-group">
          <label>*Desired Retirement Age</label>
          <input
            type="number"
            value={retirementAge}
            onChange={(e) => setRetirementAge(e.target.value)}
          />
          <div className="slider">
            <input
              type="range"
              min="30"
              max="100"
              value={retirementAge}
              onChange={(e) => setRetirementAge(e.target.value)}
            />
            <div className="slider-labels">
              <span>30</span>
              <span>100</span>
            </div>
          </div>
        </div>
        <button className="next-btn">Next</button>
      </div>
    </div>
  );
};

export default Retirement;
