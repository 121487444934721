import React from 'react'
import './Thanku.css'
import Logo from '../components/Sidebar/invest369logo 2.png'
import thanks from '../assets/thanks.png'
const Thanku = ({ isOpen, onClose }) => {

  return (
    <>
    <div className="modal-overlay4">
        <div className="modal-content4">
            <img src={Logo} className="logo-icon" alt="Logo Icon" />
            <button className="close-button"onClick={onClose}>&times;</button>
            <h2 className='thanku-heading'>Welcome to Invest369.Ai</h2>
            <img src={thanks} className="Thanks-icon" alt="Thanku Icon" />
            <p className='thanks-footer'>Thank you</p>
    </div>
    </div>
    </>
  )
}

export default Thanku
