import React, { useState } from 'react';
import './SignUp.css';
import Login from './Login';
import Forgot from './Forgot';
import Thanku from './Thanku';

const SignUp = ({ isOpen, onClose }) => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isForgotOpen, setIsForgotOpen] = useState(false);
  const [isThankuOpen, setIsThankuOpen] = useState(false);

  const handleLoginClick = () => {
    setIsLoginOpen(true);
    setIsForgotOpen(false);
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
    setIsForgotOpen(false);
    onClose();
  };

  const handleThankuClick = () => {
    setIsThankuOpen(true);
    setIsLoginOpen(false);
    setIsForgotOpen(false);
  };

  const handleCloseThanku = () => {
    setIsThankuOpen(false);
    setIsLoginOpen(false);
    setIsForgotOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
    {!isLoginOpen && !isForgotOpen && !isThankuOpen &&(
      <div className="modal-overlay3">
        <div className="modal-content3">
          <h2 className='signup-heading'>Sign up Now</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
          <form className='signup-form'>
            <div className='name-surname'>
              <input
                className='name-input'
                type="text"
                placeholder="Firstname"
                required
              />
              <input
                className='name-input'
                type="text"
                placeholder="Lastname"
                required
              />
            </div>
            <div className='input-container2'>
              <input
                className='signup-input'
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div className='input-container2'>
              <input
                className='signup-input'
                type="number"
                placeholder="Mobile No"
                required
              />
            </div>
            <div className='input-container2'>
              <input
                className='signup-input'
                type="password"
                placeholder="Password"
                required
              />
            </div>
            <div className='input-container2'>
              <input
                className='signup-input'
                type="password"
                placeholder="Confirm Password"
                required
              />
            </div>
            <button type="submit" className="signin-btn" onClick={handleThankuClick}>Sign Up</button>
          </form>
          <div className="signup-container">
            <p>Already have an account ? <span className="signup-link" onClick={handleLoginClick}>Sign In</span></p>
          </div>
        </div>
      </div>
    )}
      {isLoginOpen && <Login isOpen={isLoginOpen} onClose={handleCloseLogin} />}
      {isThankuOpen && <Thanku isOpen={isThankuOpen} onClose={handleCloseThanku} />}
      {isForgotOpen && <Forgot isOpen={isForgotOpen} />}
    </>
  );
};

export default SignUp;
